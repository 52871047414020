import React, { useEffect, useRef } from 'react';

const ChatInput = ({ 
  thisCb, 
  inputTextareaRef,  
  setInputTextareaRef,
  callService,
  conversationId,
  isRealTime,
  isWithinCustomerServiceHours,
  isModalView,

  message,
  handleChange,
  handleKeyPress,
  sendMessage,

  sendIconColor,
  sendIconSize,
  inputFontSize,
  inputTextColor,
  inputMarginColor,
  inputBackgroundColor
}) => {  

  return (

    <div style={{ 
      display:'flex', 
      justifyContent:'center', 
      padding: '10px 6px 7px 16px',
      backgroundColor: inputMarginColor,
      width: '348px',
    }}>

      {thisCb?.supportsHumanCustomerService &&
        isWithinCustomerServiceHours(thisCb?.customerServiceSchedule) && 

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '3px',
          }}
        >
          <button 
            className="send-message call-service" 
            onClick={callService} 
            style={{ 
              color: (!conversationId ? 'lightgrey' : sendIconColor),
              padding: '0px 5px 0px 0px',
              backgroundColor: inputMarginColor,
            }}
          >
            {isRealTime ? (
              <i 
                //className="bx bxs-bot" 
                //className="fa fa-robot"
                className="fa fa-step-backward"
                style={{ 
                  fontSize: `${sendIconSize}px`,
                }}>
              </i>
            ) : (
              <i 
                //className="bx bxs-user-detail" 
                className="fa fa-user"
                style={{ 
                  fontSize: `${sendIconSize*1.2}px`,
                }}>
              </i>
            )}
          </button>
        </div>
      }

      <textarea
        rows="1"
        ref={setInputTextareaRef}
        placeholder="想問什麼呢？" 
        value={message}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        style={{ 
          maxHeight:'5rem', 
          width: "100%", 
          border:'none', 
          resize: 'none',
          outline: '0px',
          WebkitAppearance: 'none',
          boxShadow: 'none',
          height: '40px',
          borderRadius: '10px',
          padding: `calc(1 / 2*(40px - (${inputFontSize*1.5}px)))`,
          fontSize: `${inputFontSize}px`,
          marginTop: `${Math.max((sendIconSize*1.2-40)/2, 0)}px`,
          color: inputTextColor,
          backgroundColor: inputBackgroundColor,
        }}
      />

      {/* Send Message Button */}
      <div style={{ display: 'flex', paddingTop: '0px', marginLeft: '6px', width: `${sendIconSize*2}px` }}>
        <button 
          className="send-message" 
          onClick={sendMessage} 
          style={{ 
            padding: '0rem 0.3rem',
            backgroundColor: inputMarginColor,
          }}
        >
          <svg
            width={sendIconSize}
            height={sendIconSize}
            viewBox="0 0 24 24"
            fill={sendIconColor}
            xmlns="http://www.w3.org/2000/svg"
          >{/*➤*/}
            <path d="M2 21l21-9L2 3v7l15 2-15 2v7z" />
          </svg>
        </button>
      </div>

    </div>

  );
};

export default ChatInput;



