import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import StarRating from './StarRating';

const RatingModal = ({
  isOpen,
  toggle,
  temporateRating,
  onRatingSelected,
  onResetRating,
  customerComment,
  onCustomerCommentChange,
  clientCommentRef,
  wordLimit,
  headerFontSize,
  onConfirm,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ padding: '1.5rem 3rem' }}>
        <p style={{ margin: '0', fontSize: `${headerFontSize}px` }}>為此對話評分</p>
      </ModalHeader>
      <ModalBody
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0',
        }}
      >
        <Row
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '0px 2rem',
          }}
        >
          <Col xs={12} sm={9} style={{ display: 'flex', justifyContent: 'center' }}>
            <StarRating
              selectedRating={temporateRating}
              onRatingSelected={onRatingSelected} // Update the rating
            />
          </Col>
          <Col xs={12} sm={3} style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px' }}>
            <Button
              color="outline-secondary"
              onClick={onResetRating}
              style={{ cursor: 'pointer', fontSize: '12px', maxWidth: '100%' }}
            >
              重置
            </Button>
          </Col>
        </Row>

        <div style={{ width: '80%' }}>
          <textarea
            className="customer-comment"
            rows="1"
            placeholder="[您的評論(選填)]"
            value={customerComment}
            onChange={onCustomerCommentChange}
            ref={clientCommentRef}
            style={{
              resize: 'none',
              outline: '0px',
              height: '100px',
              border: '1.5px solid #acbcbc',
              borderRadius: '10px',
              fontSize: '20px',
              marginTop: '30px',
              color: 'rgb(139 139 139)',
              backgroundColor: 'rgb(255, 255, 255)',
              width: '100%',
              padding: '10px'
            }}
          />
          <div>還可輸入 {wordLimit - customerComment.length} 個字</div>
        </div>
      </ModalBody>
      <ModalFooter style={{ padding: '1rem 1.5rem', gap: '0.7rem' }}>
        <Button color="primary" onClick={onConfirm}>
          完成
        </Button>
        <Button color="secondary" onClick={toggle}>
          取消
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RatingModal;



